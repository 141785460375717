<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Bonita Layout',
  colors: {
    fields: {
      palette: true,
      picker: true,
    },
    background: {
      palette: true,
      picker: true,
    },
    foreground: {
      palette: true,
      picker: true,
    },
  },
  colorPalette: {
    '#20432C': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#7A8F70': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#E4E2D4': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#DF6548': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#FA9CA2': {
      fields: true,
      background: true,
      foreground: true,
    },
    '#E7AD16': {
      fields: true,
      background: true,
      foreground: true,
    },
  },
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/bonita/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/bonita/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/bonita/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/bonita/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6', links)

const currentResortInStaging = true
const {
  resorts,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands'], currentResortInStaging)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#20432C' })
provide('currentResort', currentResort?.value.data)
provide('currentResortInStaging', currentResortInStaging)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white bonita-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />

        <BonitaNavbar
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :resorts="resorts?.data"
        />

        <slot />

        <BonitaFooter
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :footer-company-menu="footerCompanyMenu?.data"
          :footer-resources-menu="footerResourcesMenu?.data"
          :footer-terms-menu="footerTermsMenu?.data"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/sass/bonita.scss';
</style>
